<template>
  <div>
    <v-card class="mb-6">
      <v-card-title>{{ $t('contact.subtitle') }}</v-card-title>
      <v-card-text>
        <p v-for="content in $t('contact.content')" :key="content">
          {{ content }}
        </p>
        <h2 class="text-xl font-weight-semibold mb-2">
          {{ $t('contact.info.title') }}
        </h2>
        <v-divider />
        <v-list>
          <v-list-item dense class="px-0 mb-n2">
            <span class="font-weight-medium text-no-wrap me-2">{{ $t('contact.info.email') }}</span>
            <span class="text--secondary"><a :href="'mailto:' + contact.email">{{ contact.email }}</a></span>
          </v-list-item>
          <v-list-item dense class="px-0 mb-n2">
            <span class="font-weight-medium me-2">Contact:</span>
            <span class="text--secondary"><a :href="'tel:' + contact.phone.int">{{ contact.phone.display }}</a></span>
          </v-list-item>
        </v-list>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
  export default {
    data () {
      return {
        contact: {
          email: 'bonjour@burgerbreton.com',
          phone: {
            int: '+33183439836',
            display: '01 83 43 98 36'
          }
        }
      };
    }
  };
</script>
